/* CardComponent.css */
.card {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-img-top {
    transition: transform 0.3s ease-in-out;
  }
  
 
  