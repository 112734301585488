/* ImageArtists.css */
.image-container {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .image-container:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .image-container img {
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .image-container:hover img {
    transform: scale(1.1);
  }
  
  .artist-name {
    font-weight: bold;
    margin-top: 10px;
  }
  
  .heading {
    text-align: center;
    padding: 20px;
    font-size: 2rem;
    color: #333;
  }
  