/* HistoryComponent.css */
.carousel-item img {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }
  
  .carousel-item:hover img {
    transform: scale(1.05);
    opacity: 0.9;
  }
  
  .history-heading {
    text-align: center;
    padding: 20px;
    font-size: 2.5rem;
    color: #333;
  }
  
  .history-paragraph {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
    color: #555;
    padding: 15px;
    background-color: #f9f9f9;
    border-left: 4px solid #333;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .history-paragraph:first-letter {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .history-paragraph:hover {
    background-color: #f0f0f0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  