/* carousel.css */
.carousel-inner {
    perspective: 1000px;
  }
  
  .carousel-item {
    transition: transform 0.8s ease-in-out;
    backface-visibility: hidden;
    transform-style: preserve-3d;
  }
  
  .carousel-item-next,
  .carousel-item-prev {
    position: absolute;
    top: 0;
    width: 100%;
    transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
  }
  
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: rotateY(0deg);
  }
  
  .carousel-item-next,
  .active.carousel-item-left {
    transform: rotateY(90deg);
  }
  
  .carousel-item-prev,
  .active.carousel-item-right {
    transform: rotateY(-90deg);
  }
  